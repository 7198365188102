import {api} from "../plugins/axios";
import {Paged, TResponse} from "../types/types";
import {
    NotiBadgeDto,
    NotiCategoryDto,
    NotiMessageDto,
    PushSettingsDto,
    PushSettingsUpdateDto
} from "../types/notification";

export const getNotification = (page?: number, size?: number, categoryString?: string) => {
    const url = `/v1/notification`;
    const category = categoryString ? parseInt(categoryString) : undefined;
    const params = {page, size, category};
    return api.get<TResponse<Paged<NotiMessageDto>>>(url, {params});
}

export const getNotiBadge = () => {
    const url = `/v1/notification/badge`;
    return api.get<TResponse<NotiBadgeDto>>(url);
}

export const getNotiCategories = () => {
    const url = `/v1/notification/categories`;
    return api.get<TResponse<NotiCategoryDto[]>>(url);
}

export const postNotiRead = (ids: number[]) => {
    const url = `/v1/notification/read`;
    return api.post(url, ids);
}

export const getNotiSettings = () => {
    const url = `/v1/notification/settings`;
    return api.get<TResponse<PushSettingsDto>>(url);
}

export const postPushSettings = (data: PushSettingsUpdateDto) => {
    const url = `/v1/notification/settings`;
    return api.post(url, data);
}

export const getNotiDetail = (id: number) => {
    const url = `/v1/notification/${id}`;
    return api.get<TResponse<NotiMessageDto>>(url);
}
