import { useHistory } from 'react-router-dom';
import { useEffect, useLayoutEffect, useRef } from 'react';

const ScrollRestoration = () => {
  const history = useHistory();
  const currentLocation = useRef(history.location.pathname);

  // scroll 위치 저장
  useLayoutEffect(() => {
    window.history.scrollRestoration = 'manual';
    let prevLocation: any = {};
    const unsubscribe = history.listen((nextLocation) => {
      if (history.location.key) {
        sessionStorage.setItem(prevLocation.current ?? currentLocation.current, JSON.stringify(window.scrollY));
      }
      prevLocation.current = nextLocation.pathname;
    });
    return () => {
      unsubscribe();
    };
  }, [history]);

  // scroll 이동
  useEffect(() => {
    if (!('scrollRestoration' in window.history)) return;
    if (history.location.pathname.indexOf('/p/') === -1 && history.location.pathname.indexOf('/results/') === -1 && history.location.pathname !== '/journal') {
      scrollTo(0);
    } else {
      const cache = sessionStorage.getItem(history.location.pathname) ?? 0;
      // scrollTo(cache);
      setTimeout(() => {
        scrollTo(cache);
      }, 200);
    }
  }, [history.location.pathname]);
  return null;
};

export const scrollTo = (offset: any) => {
  window.scrollTo(0, offset);
};

export default ScrollRestoration;