import airbridge from 'airbridge-web-sdk-loader';
import amplitude from 'amplitude-js';
import { parse } from 'qs';
import { isMobile } from 'react-device-detect';
import { AIRBRIDGE_EVENT } from '../constants/airbridge';
import { BRAZE_EVENT } from '../constants/braze';
import { historyState } from './ridge';
import debug from '../utils/logging.js';

interface AirBridgeEventOption {
  action?: string;
  label?: string;
  value?: number;
  customAttributes?: object;
  semanticAttributes?: object;
}

export enum Action {
  sendAirbridge = 'sendAirbridge',
  sendAmplitude = 'sendAmplitude',
  sendBraze = 'sendBraze',
  sendAnalytics = 'sendAnalytics',
  error = 'error',
  successRedirectUrl = 'successRedirectUrl',
  setToken = 'setToken',
  getToken = 'getToken',
  removeToken = 'removeToken',
  goBack = 'goBack',
  openSettings = 'openSettings',
  badgeCount = 'badgeCount',
}

interface Command {
  kind?: 'webview';
  action: Exclude<
    keyof typeof Bridge,
    'prototype' | 'os' | 'postMessage' | 'handleMessageEvent'
  >;
  value?: any;
}

function tryParseJSON(jsonString: any) {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    return null;
  }
}

export class Bridge {
  static os: string;

  static getToken(token: string) {
    localStorage.setItem('authToken', token);
  }

  static setIdfaAdid(value: string) {
    localStorage.setItem('x-client-id', value);
  }

  static historyPush(value: string) {
    const history = historyState.get();
    if (history && history?.push) {
      history.push(value);
      historyState.set(history);
    } else {
      window.location.href = value;
    }
  }

  static historyReplace(value: string) {
    const history = historyState.get();
    if (history && history?.replace) {
      history.replace(value);
      historyState.set(history);
    } else {
      window.location.href = value;
    }
  }

  static goBack() {
    const history = historyState.get();
    if (history && history?.goBack) {
      history.goBack();
      historyState.set(history);
    }
  }

  static redirectUrl(url: string) {
    const token = localStorage.getItem('token');
    const splitUrl = url.split('?');
    const query = splitUrl.length > 1 ? splitUrl[1] : '';
    const { isLogin } = parse(query);
    Bridge.postMessage(Action.successRedirectUrl);
    if (isLogin && !token) {
      this.historyReplace('/login?location=' + url);
      return;
    }
    this.historyPush('/' + url);
  }

  static defineDevice(ua: string) {
    if (ua.indexOf('Android') !== -1) {
      if (ua.indexOf(' wv') !== -1) return true;
      else return false;
    } else if (ua.indexOf('Safari') === -1) {
      return true;
    }
    return false;
  }

  static postMessage(action: Action, value?: any) {
    const data = JSON.stringify({ action, value });
    (window as any).ReactNativeWebView?.postMessage(data);
  }

  static handleMessageEvent({ data }: MessageEvent) {
    const command = tryParseJSON(data) as Command;
    if (command?.kind !== 'webview') return;
    if (Bridge[command.action]) {
      Bridge[command.action](command.value);
    } else {
      Bridge.postMessage(
        Action.error,
        `(RN -> WebView) Invalid action: ${data}`,
      );
    }
  }

  //ts-ignore
  static sendAirbridge(
    category: string,
    option?: AirBridgeEventOption,
    signupOption?: any,
  ) {
    try {
      debug.log('Airbridge Event: ' + category + ' option: ', option);
      // if ((isMobile && navigator.userAgent.indexOf(' wv) ') !== -1) || (isMobile && navigator.userAgent.indexOf(' AppleWebKit/') !== -1)) {
      if (isMobile && this.defineDevice(navigator.userAgent)) {
        if (
          (category === AIRBRIDGE_EVENT.signUp ||
            category === 'setUserId' ||
            category === AIRBRIDGE_EVENT.signIn) &&
          signupOption
        ) {
          Bridge.postMessage(Action.sendAirbridge, { category, signupOption });
          return;
        }
        Bridge.postMessage(Action.sendAirbridge, { category, option });
        return;
      }
      if (category === 'setUserId') {
        airbridge.setUserId(String(signupOption));
      }
      if (category === 'clearUser') {
        airbridge.clearUser();
        return;
      }
      if (
        (category === AIRBRIDGE_EVENT.signUp ||
          category === AIRBRIDGE_EVENT.signIn) &&
        signupOption
      ) {
        const option = {
          attributes: signupOption.userAttribute,
        };
        if (category === AIRBRIDGE_EVENT.signUp)
          airbridge.events.signUp(option);
        else if (category === AIRBRIDGE_EVENT.signIn) {
          airbridge.events.signIn(option);
        }
        return;
      }
      airbridge.events.send(category, option);
      return;
    } catch (e) {
      debug.log(e);
    }
  }

  static async sendAmplitude(eventName: string, eventProperties?: any) {
    try {
      if (!amplitude) {
        const amplitude = await import('amplitude-js');
      }
      // if ((isMobile && navigator.userAgent.indexOf(' wv) ') !== -1) || (isMobile && navigator.userAgent.indexOf(' AppleWebKit/') !== -1)) {
      if (isMobile && this.defineDevice(navigator.userAgent)) {
        Bridge.postMessage(Action.sendAmplitude, {
          eventName,
          eventProperties,
        });
        return;
      } else {
        debug.log(
          `[Amplitude Event] ${eventName} ${JSON.stringify(eventProperties)}`,
        );
        if (eventName === 'getDeviceId') {
          amplitude.getInstance().setDeviceId(eventProperties);
          return;
        }
        if (eventName === 'setUserId') {
          amplitude.getInstance().setUserId(eventProperties);
          return;
        }

        if (eventName === 'sign_up_completed') {
          amplitude.getInstance().setUserId(eventProperties.userId);
          amplitude.getInstance().setUserProperties(eventProperties.option);
          amplitude
            .getInstance()
            .logEvent(eventName, { platform: eventProperties?.platform });
          amplitude.getInstance('worksout').setUserId(eventProperties.userId);
          amplitude
            .getInstance('worksout')
            .setUserProperties(eventProperties.option);
          amplitude
            .getInstance('worksout')
            .logEvent(eventName, { platform: eventProperties?.platform });
          return;
        }

        if (eventName.indexOf('setUserProperties#') === 0) {
          var userProperty: any = {};
          const name = eventName.substring(18, eventName.length);
          userProperty[name] = eventProperties;
          debug.log('userproperties: ', userProperty);
          amplitude.getInstance().setUserProperties(userProperty);
          return;
        }
        if (eventName === 'AMPLITUDE_REVENUE') {
          let revenue_obj = new amplitude.Revenue()
            .setPrice(eventProperties.price)
            .setRevenueType(eventProperties.type)
            .setEventProperties(eventProperties.eventProperties);
          amplitude.getInstance().logRevenueV2(revenue_obj);
          return;
        } else {
          amplitude.getInstance().logEvent(eventName, eventProperties);
          return;
        }
      }
    } catch (e) {
      debug.log(e);
    }
  }

  static sendBraze(eventName: string, eventProperties?: any) {
    try {
      const appboy = require('@braze/web-sdk');
      // 임시 주석처리 TODO
      // if ((isMobile && navigator.userAgent.indexOf(' wv) ') !== -1) || (isMobile && navigator.userAgent.indexOf(' AppleWebKit/') !== -1)) {
      if (isMobile && this.defineDevice(navigator.userAgent)) {
        Bridge.postMessage(Action.sendBraze, { eventName, eventProperties });
        return;
      }
      debug.log(eventName + ' sended..! From web');
      if (eventName === 'getDeviceId') {
        appboy.getDeviceId(function(deviceId: string) {
          debug.log('The device id is ' + deviceId);
          Bridge.sendAmplitude('getDeviceId', deviceId);
        });
        return;
      }
      if (eventName === 'changeUser') {
        appboy.changeUser(eventProperties.toString());
        return;
      }
      if (eventName === 'withdraw') {
        appboy.getUser().setCustomUserAttribute('account_status', '탈퇴');
        return;
      }

      if (eventName === 'modifyingGrant') {
        debug.log(eventProperties);
        if (eventProperties?.EmailNotificationSubscriptionType === -1)
          appboy.getUser().setEmailNotificationSubscriptionType('unsubscribed');
        else appboy.getUser().setEmailNotificationSubscriptionType('opted_in');
        if (eventProperties?.PushNotificationSubscriptionType === -1)
          appboy.getUser().setPushNotificationSubscriptionType('unsubscribed');
        else appboy.getUser().setPushNotificationSubscriptionType('opted_in');

        return;
      }
      if (eventName === 'brazeSetting') {
        appboy.changeUser(eventProperties.userId.toString());
        appboy.getUser().setFirstName(eventProperties.firstName);
        appboy.getUser().setEmail(eventProperties.email);
        appboy.getUser().setPhoneNumber(eventProperties.PhoneNumber);
        appboy
          .getUser()
          .setDateOfBirth(
            eventProperties.year,
            eventProperties.month,
            eventProperties.day,
          );
        appboy.getUser().setGender(eventProperties.gender);
        appboy
          .getUser()
          .setEmailNotificationSubscriptionType(
            eventProperties.EmailNotificationSubscriptionType,
          );
        appboy
          .getUser()
          .setPushNotificationSubscriptionType(
            eventProperties.PushNotificationSubscriptionType,
          );
        appboy
          .getUser()
          .setCustomUserAttribute(
            'sign_up_date',
            eventProperties.customUserAttribute.sign_up_date,
          );
        appboy
          .getUser()
          .setCustomUserAttribute(
            'WO_membership',
            eventProperties.customUserAttribute.WO_membership,
          );
        appboy
          .getUser()
          .setCustomUserAttribute(
            'account_status',
            eventProperties.customUserAttribute.account_status,
          );
        appboy.requestImmediateDataFlush();
        return;
      }
      if (eventName === BRAZE_EVENT.PURCHASE) {
        // const { productId, price, currencyCode, quantity, CH_purchased_categories } = eventProperties;
        debug.log(eventProperties);
        appboy
          .getUser()
          .setCustomUserAttribute(
            'CH_purchased_categories',
            eventProperties.CH_purchased_categories,
          );
        if (eventProperties.non_member_order === true) {
          appboy
            .getUser()
            .setCustomUserAttribute('phone', eventProperties.phone);
          appboy.getUser().addAlias(eventProperties.phone, 'phone');
        }
        appboy.logPurchase(
          eventProperties.productId.toString(),
          eventProperties.price,
          eventProperties.currencyCode.toString(),
          eventProperties.quantity,
          {
            raffle_status: eventProperties.raffle_status,
            non_member_order: eventProperties.non_member_order,
            item_id: eventProperties.item_id,
            item_name: eventProperties.item_name,
            item_brand: eventProperties.item_brand,
            item_category: eventProperties.item_category,
            item_color: eventProperties.item_color,
            item_gender: eventProperties.item_gender,
            item_size: eventProperties.itme_size,
            item_price: eventProperties.item_price,
            item_discount_rate: eventProperties.item_discount_rate,
            item_discount_price: eventProperties.item_discount_price,
            order_item_quantity: eventProperties.order_item_quantity,
            total_order_item_quantity:
            eventProperties.total_order_item_quantity,
            payment_method: eventProperties.payment_method,
            paid_shipping: eventProperties.paid_shipping,
            paid_point: eventProperties.paid_point,
            coupon_id: eventProperties.coupon_id,
            coupon_name: eventProperties.coupon_name,
            reward_point: eventProperties.reward_point,
            order_id: eventProperties.order_id.toString(),
            order_total: eventProperties.order_total,
          },
        );
        // appboy.logPurchase(
        //   productId,
        //   price,
        //   currencyCode,
        //   quantity,
        //   omit(eventProperties, ['productId', 'price', 'currencyCode', 'quantity', 'CH_purchased_categories']),
        // );
        return;
      }
      appboy.logCustomEvent(eventName, eventProperties);
      appboy.requestImmediateDataFlush();
      return;
    } catch (e) {
      debug.log(e);
    }
  }
}

// @ts-ignore
document.addEventListener('message', Bridge.handleMessageEvent);
window.addEventListener('message', Bridge.handleMessageEvent);
