import NotificationTitle from "../../components/Notification/NotificationTitle";
import {useEffect, useState} from "react";
import {PushSettingsItemDto, PushSettingsUpdateDto} from "../../types/notification";
import {useForm} from "react-hook-form";
import {getNotiSettings, postPushSettings} from "../../apis/notification";
import {nToast} from "../../plugins/reactToast";
import {isIOS} from "react-device-detect";
import moment from "moment";
import {Action, Bridge} from "../../plugins/bridge";
import useNotification from "../../hooks/useNotification";

const NotificationSettingPage = () => {
    const {
        form,
        onChangePushOn,
        isChecked,
        onChangePushDetails
    } = useNotification();

    return (
        <div>
            <NotificationTitle title={'알림센터 설정'}/>
            <SettingCategory title={'PUSH 설정'} description={'칼하트윕의 PUSH 알림 수신 여부를 설정합니다.'}/>
            <SettingItem title={'[PUSH 알림]'} description={'칼하트윕의 PUSH 알림 수신 여부를 설정합니다.'}
                         checked={form.watch('pushOn')}
                         onChange={onChangePushOn}
            />
            <div className={'py-4 text-gray-500 text-12'}>
                ※ 칼하트윕의 중요 안내사항 및 주문/배송에 대한 정보는 위 수신여부와 관계없이 발송됩니다.
            </div>
            {/*<SettingCategory title={'PUSH 상세 설정'} description={'카테고리별 PUSH 알림 수신 여부를 설정합니다.'}/>*/}

            {/*<SettingItem title={'[에디토리얼 알림 설정]'} description={'에디토리얼 소식을 전달드립니다.'}*/}
            {/*             checked={isChecked(9)}*/}
            {/*             onChange={() => onChangePushDetails(9, '에디토리얼')}*/}
            {/*/>*/}

            {/*<SettingItem title={'[신상품 입고 알림 설정]'} description={'신상품 입고 소식을 전달드립니다.'}*/}
            {/*             checked={isChecked(10)}*/}
            {/*             onChange={() => onChangePushDetails(10, '신상품 입고')}*/}
            {/*/>*/}

            {/*<SettingItem title={'[프로모션 알림 설정]'} description={'프로모션 소식을 전달드립니다.'}*/}
            {/*             checked={isChecked(7)}*/}
            {/*             onChange={() => onChangePushDetails(7, '프로모션')}*/}
            {/*/>*/}

            <div className={'py-4 text-gray-500 text-12'}>
                PUSH를 설정하였으나 알림이 오지 않을 경우 휴대폰의 설정을 확인바랍니다. <br/>
                <button className={'underline text-black font-medium'}
                   onClick={() => Bridge.postMessage(Action.openSettings)}>
                    핸드폰 설정 바로가기 &gt;
                </button>
            </div>
        </div>
    )
}


type SettingCategoryProps = {
    title: string;
    description: string;
}

const SettingCategory = (props: SettingCategoryProps) => {
    return (
        <div className={'py-4'}>
            <div className={'font-bold text-14'}>{props.title}</div>
            <div className={'text-12'}>{props.description}</div>
        </div>
    )
}

type SettingItemProps = {
    title: string;
    description: string;
    checked: boolean;
    onChange: () => void;
}

const SettingItem = (props: SettingItemProps) => {
    return (
        <div className={'flex justify-between border-b p-4 -mx-4'}>
            <div>
                <div className={'font-bold text-12'}>{props.title}</div>
                <div className={'text-gray-500 text-12'}>{props.description}</div>
            </div>
            <Switcher10 checked={props.checked} onChange={props.onChange} />
        </div>
    )
}

type SwitcherProps = {
    checked: boolean;
    onChange: () => void;
}

const Switcher10 = (props: SwitcherProps) => {
  return (
    <>
      <label className='autoSaverSwitch relative inline-flex cursor-pointer select-none items-center'>
        <input
          type='checkbox'
          name='autoSaver'
          className='sr-only'
          checked={props.checked}
          onChange={props.onChange}
        />
        <span
          className={`slider flex h-[30px] w-[50px] items-center rounded-full p-1 duration-200 ${
            props.checked ? 'bg-black' : 'bg-[#CCCCCE]'
          }`}
        >
          <span
            className={`h-6 w-6 rounded-full bg-white duration-200 ${
              props.checked ? 'translate-x-[18px]' : ''
            }`}
          ></span>
        </span>
      </label>
    </>
  )
}

export default NotificationSettingPage;
