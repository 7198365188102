import NotificationTabs from "../../components/Notification/NotificationTabs";
import NotificationTitle from "../../components/Notification/NotificationTitle";
import NotificationListItem from "../../components/Notification/NotificationListItem";
import {Section} from "../../components/Section";
import {Table} from "../../components/Table";
import React, {useEffect, useState} from "react";
import {ReactComponent as BellIcon} from "../../assets/svg/icon-bell.svg";
import {getNotification, postNotiRead} from "../../apis/notification";
import {NotiMessageDto} from "../../types/notification";
import moment from "moment";
import {useHistory} from "react-router-dom";
import qs from "qs";
import { Icon } from '../../components/Icon';
import {useUserState} from "../../context/UserContext";
import {nToast} from "../../plugins/reactToast";

const NotificationListPage = () => {
    const history = useHistory();
    const query = qs.parse(history.location.search, {ignoreQueryPrefix: true});
    const tabId = query.tab?.toString() ?? undefined;
    const page = Number(query.page ?? '0');
    const limit = Number(query.limit ?? '10');
    const [notifications, setNotifications] = useState<NotiMessageDto[]>([]);
    const { isLoggedIn } = useUserState();

    const onClickNotification = (notification: NotiMessageDto) => {

        const openNotification = () => {
            if (notification.linkUrl) {
                window.location.href = notification.linkUrl;
            } else {
                history.push(`/notification/${notification.id}`);
            }
        }

        if (isLoggedIn) {
            postNotiRead([notification.id]).then(() => {
                getNotificationList();
                openNotification();
            });
        } else {
            openNotification();
        }
    }

    const getNotificationList = () => {
        getNotification(page, limit, tabId)
            .then((res) => {
                setNotifications(res.data.payload.content);
            });
    }

    const readAll = () => {
        const ids = notifications.map((notification) => notification.id);
        postNotiRead(ids).then(() => getNotificationList());
    }

    useEffect(() => {
        getNotificationList();
    }, [tabId]);

    useEffect(() => {
        if (page > 0) {
            getNotification(page, limit, tabId)
                .then((res) => {
                    const data = res.data.payload.content;
                    setNotifications([...notifications, ...data]);
                    if (data.length === 0) nToast('마지막 알림입니다.');
                });
        }
    }, [page]);

    return (
        <div>
            <NotificationTitle title={'알림센터'} showIcon={true}/>

            <NotificationTabs/>

            {notifications.length === 0 && <EmptyNotificationList/>}

            {notifications.length > 0 && (
                <>
                    <Section className="md:hidden">
                        <div className={'-mx-4 bg-gray-100'}>

                            {isLoggedIn && (
                                <div className={'pt-4 px-4 text-right text-gray-500 text-12'}>
                                    <button onClick={readAll}>전체읽음</button>
                                </div>
                            )}

                            <div className={'p-4 space-y-4'}>
                                {notifications.map((notification) => (
                                    <NotificationListItem key={notification.id}
                                                          notification={notification}
                                                          onclick={() => onClickNotification(notification)}
                                    />
                                ))}
                            </div>
                        </div>
                    </Section>

                    <Table className={'hidden md:table my-10 mx-20 lg:mx-30'}>
                        <Table.Head>
                            <Table.Row>
                                <Table.Th>순번</Table.Th>
                                <Table.Th>제목</Table.Th>
                                <Table.Th>등록일</Table.Th>
                            </Table.Row>
                        </Table.Head>
                        <Table.Body>
                            {notifications.map((notification, index) => (
                                <Table.Row key={notification.id}
                                           className={'cursor-pointer'}
                                           onClick={() => onClickNotification(notification)}
                                >
                                    <Table.Td>{index + 1}</Table.Td>
                                    <Table.Td>{notification.title}</Table.Td>
                                    <Table.Td>{moment(notification.displayStartDate).format('YYYY.MM.DD')}</Table.Td>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>

                    <div className={'flex justify-center py-8'}>
                        <button className="border border-gray-300 flex px-6 text-xs py-3"
                                onClick={() => history.replace({search: `?tab=${tabId || ''}&page=${page + 1}`})}
                        >
                            더 보기
                            <Icon.ChevronDown className={'w-4 h-4 ml-1'}/>
                        </button>
                    </div>
                </>
            )}

        </div>
    )
}

const EmptyNotificationList = () => {
    return (
        <div className={'flex flex-col space-y-2 items-center py-12'}>
            <BellIcon/>
            <p className={'text-12'}>새로운 알림이 없습니다.</p>
        </div>
    )
}

export default NotificationListPage;
